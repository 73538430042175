<template>
  <div>
    <app-header />

    <div
      class="
        uk-section uk-padding-remove-vertical uk-margin-remove-vertical
        in-equity-breadcrumb
      "
    >
      <div class="uk-container">
        <div class="uk-grid">
          <div class="uk-width-1-1">
            <ul class="uk-breadcrumb">
              <li>
                <router-link :to="{ name: 'public.home' }">Home</router-link>
              </li>
              <li><span>Register</span></li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div
      class="
        uk-section uk-section-muted uk-flex uk-flex-middle uk-animation-fade
      "
      style="padding-top; 0"
      uk-height-viewport
    >
      <div class="uk-width-1-1" style="margin-top: -100px">
        <div class="uk-container">
          <div class="uk-grid-margin uk-grid uk-grid-stack" uk-grid>
            <div class="uk-width-1-1@m">
              <div
                class="
                  uk-margin
                  uk-width-large
                  uk-margin-auto
                  uk-card
                  uk-card-default
                  uk-card-body
                  uk-box-shadow-large
                "
              >
                <h3 class="uk-card-title uk-text-center">Your Account Has Been Created</h3>
                <p>Click on the link in your email to activate your account.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-footer />
  </div>
</template>

<script>
import AppHeader from "../components/AppHeader";
import AppFooter from "../components/AppFooter";

export default {
  components: {
    AppHeader,
    AppFooter,
  }
};
</script>
